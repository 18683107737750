<template>
    <AppMultiselect
        v-model="value"
        @change="change"
        :service="$service"
        optionLabel="name"
        :filterFields="filterFields"
        :recordsPerPage="recordsPerPage"
        :filtrosExtras="filtrosExtras"
    />
</template>
<script>
import BaseService from '@/services/BaseService';

export default {
    name: 'MultiSelectUnidadePorCliente',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object
        },
        change: {
            type: Function
        },
        recordsPerPage: {
            type: Number,
            default: 10
        },
        filtrosExtras: {
            type: Object
        },
        filterFields: {
            type: Array,
            default: () => ['name']
        }
    },
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.$service = new BaseService(`/customer/branches/combo-by-customer`);
    }
};
</script>
