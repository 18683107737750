<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                :activateRecord="false"
                :saveData="completeForm"
                backPath="/relatorios-agendados/list"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="card p-fluid w-full">
                        <div class="grid" v-if="isEditMode">
                            <div class="field col-6">
                                <label for="tipo">Tipo de Relatório</label>
                                <InputText id="tipo" v-model="form.tipo" :disabled="isEditMode" :class="{ 'p-invalid': submitted && !form.tipo }" />
                                <small v-if="submitted && !form.tipo" class="p-error">Campo obrigatório.</small>
                            </div>
                            <div class="field col-3 flex flex-column align-items-center justify-content-center">
                                <div class="flex flex-column">Data de criação: {{ $filters.formatDateOnly(form.dataCriacao) }}</div>
                            </div>

                            <div class="field col-3 flex flex-column align-items-center justify-content-center">
                                <div class="flex flex-column">Última geração do relatório: {{ $filters.formatDateOnly(form.dataUltimaGeracao) }}</div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="cliente">Cliente<span class="p-error">*</span></label>
                                <MultiSelectCliente
                                    id="cliente"
                                    v-model="form.cliente"
                                    dataKey="id"
                                    optionLabel="name"
                                    multiple="true"
                                    required
                                    :class="{ 'p-invalid': submitted && !form.cliente.length }"
                                />
                                <small v-if="submitted && !form.cliente.length" class="p-error">Selecione pelo menos um cliente.</small>
                            </div>
                            <div class="field col-6">
                                <label for="unidade">Unidade</label>
                                <MultiSelectUnidadePorCliente
                                    id="unidade"
                                    v-model="form.unidade"
                                    dataKey="id"
                                    optionLabel="name"
                                    multiple="true"
                                    :disabled="enabledUnidade"
                                    :filtrosExtras="{ customerId: form?.cliente[0]?.id || 0 }"
                                />
                            </div>
                        </div>
                        <div class="grid" v-if="isEditMode">
                            <div class="field col-6">
                                <label for="empresa">Empresa</label>
                                <InputText id="empresa" v-model="empresa" :disabled="isEditMode" />
                            </div>
                            <div class="field col-6">
                                <label for="usuario">Usuário</label>
                                <InputText id="usuario" v-model="usuario" :disabled="isEditMode" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="dia">Dia<span class="p-error">*</span></label>
                                <InputNumber id="dia" v-model="form.dia" required @blur="validateDia" :class="{ 'p-invalid': !diaValido }" />
                                <small v-if="!diaValido" class="p-error">Selecione um dia do mês válido.</small>
                            </div>
                            <div class="field col-6">
                                <label for="mesBase">Mês Base<span class="p-error">*</span></label>
                                <Dropdown
                                    id="mesBase"
                                    v-model="form.mesBase"
                                    :options="meses"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Selecione um mês"
                                    required
                                    :class="{ 'p-invalid': submitted && !form.mesBase }"
                                />
                                <small v-if="submitted && !form.mesBase" class="p-error">Selecione um mês válido.</small>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12">
                                <label for="mensagemPersonalizada">Mensagem Personalizada</label>
                                <Textarea
                                    id="mensagemPersonalizada"
                                    v-model="form.mensagemPersonalizada"
                                    rows="3"
                                    maxlength="500"
                                    :class="{ 'p-invalid': submitted && form.mensagemPersonalizada.length > 500 }"
                                />
                                <small v-if="submitted && form.mensagemPersonalizada.length > 500" class="p-error"
                                    >A mensagem não pode exceder 500 caracteres.</small
                                >
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12">
                                <label for="emailsEnvios">Emails para Envio<span class="p-error">*</span></label>
                                <InputText id="emailsEnvios" v-model="emailString" :class="{ 'p-invalid': submitted && !emailsValidos }" />
                                <small v-if="submitted && !emailsValidos" class="p-error">Insira um endereço de e-mail válido.</small>
                            </div>
                        </div>
                        <div class="grid" v-if="isEditMode">
                            <div class="field col-12 flex flex-row align-items-center">
                                <label for="situacao" class="mr-2">Ativo</label>
                                <InputSwitch
                                    id="situacao"
                                    v-model="situacaoSwitch"
                                    onIcon="pi pi-check"
                                    offIcon="pi pi-times"
                                    onLabel="Ativo"
                                    offLabel="Inativo"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import MultiSelectUnidadePorCliente from '../../unidades/components/MultiSelectUnidadePorCliente.vue';
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente.vue';
import TipoRelatorioAgendadoEnum from '@/enums/TipoRelatorioAgendadoEnum';
import EnumSituacaoRelatoriosAgendados from '@/enums/EnumSituacaoRelatoriosAgendados';
import RelatoriosAgendadosService from '@/services/RelatoriosAgendadosService';
import { getProfile, getCompany } from '@/services/auth';
import BaseService from '../../../services/BaseService';

export default {
    components: {
        MultiSelectCliente,
        MultiSelectUnidadePorCliente
    },
    data() {
        return {
            loading: false,
            service: new RelatoriosAgendadosService('relatorios-agendados'),
            form: {
                tipo: '',
                cliente: [],
                unidade: [],
                empresa: '',
                usuario: '',
                dia: null,
                mesBase: null,
                parametros: [],
                mensagemPersonalizada: '',
                emailsEnvios: [],
                alteracoes: [],
                situacao: EnumSituacaoRelatoriosAgendados.INATIVO,
                dataCriacao: '',
                dataUltimaGeracao: ''
            },
            usuario: '',
            empresa: '',
            emailString: '',
            submitted: false,
            diaValido: true,
            emailsValidos: false,
            isEditMode: false,
            enabledUnidade: true,
            maxDia: 31,
            meses: [
                { label: 'Janeiro', value: 1 },
                { label: 'Fevereiro', value: 2 },
                { label: 'Março', value: 3 },
                { label: 'Abril', value: 4 },
                { label: 'Maio', value: 5 },
                { label: 'Junho', value: 6 },
                { label: 'Julho', value: 7 },
                { label: 'Agosto', value: 8 },
                { label: 'Setembro', value: 9 },
                { label: 'Outubro', value: 10 },
                { label: 'Novembro', value: 11 },
                { label: 'Dezembro', value: 12 }
            ]
        };
    },
    async created() {
        this.isEditMode = this.$route.params.id;
        if (this.isEditMode) {
            this.loadData();
        }
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Visualizar Relatório' : 'Adicionar Relatório';
        },
        situacaoSwitch: {
            get() {
                return this.form.situacao === EnumSituacaoRelatoriosAgendados.ATIVO;
            },
            set(value) {
                this.form.situacao = value ? EnumSituacaoRelatoriosAgendados.ATIVO : EnumSituacaoRelatoriosAgendados.INATIVO;
            }
        }
    },
    watch: {
        'form.cliente': function (value) {
            if (value && value.length === 1) {
                this.enabledUnidade = false;
            } else {
                this.enabledUnidade = true;
                this.form.unidade = [];
            }
        },
        'form.mesBase': function (newValue) {
            this.maxDia = this.calculateMaxDays(newValue);
            if (this.form.dia > this.maxDia) {
                this.form.dia = null;
            }
        },
        'form.dia': function (newValue) {
            this.diaValido = newValue >= 1 && newValue <= this.maxDia;
        }
    },
    methods: {
        calculateMaxDays(mes) {
            const ano = new Date().getFullYear();
            return new Date(ano, mes, 0).getDate();
        },
        async loadData() {
            try {
                const response = await this.service.findById(this.$route.params.id);
                this.form = response.data;
                const clientes = await Promise.all(
                    this.form.cliente.map((clienteId) => {
                        return new BaseService('/customers').findById(clienteId);
                    })
                );
                if (this.form.unidade && this.form.unidade.length > 0) {
                    const unidades = await Promise.all(
                        this.form.unidade.map((unidadeId) => new BaseService('/customer/branches').findById(unidadeId))
                    );
                    this.form.unidade = unidades.map((unidade) => unidade.data);
                }

                this.form.cliente = clientes.map((cliente) => cliente.data);
                this.emailString = this.form.emailsEnvios.join('; ');
                const usuario = await new BaseService('/users').findById(this.form.usuario);
                const empresa = await new BaseService('/companies').findById(this.form.empresa);
                this.usuario = usuario.data.name;
                this.empresa = empresa.data.name;
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao carregar dados',
                    detail: 'Não foi possível carregar os dados do relatório.',
                    life: 3000
                });
            }
        },
        async completeForm() {
            const clientes = [];
            this.form.dataCriacao = this.form.dataCriacao || new Date();
            this.form.dataUltimaGeracao = this.form.dataUltimaGeracao || new Date();
            this.form.cliente.map(async (cliente) => {
                clientes.push({
                    id: cliente.id,
                    name: cliente.name,
                    companyId: cliente.companyId,
                    ativo: cliente.ativo,
                    inscricao: cliente.inscricao
                });
            });
            this.form.infoClientes = clientes;
            this.form.cliente = this.form.cliente.map((cliente) => cliente.id);
            this.form.unidade = this.form.unidade ? this.form.unidade.map((unidade) => unidade.id) : [];
            if (!this.isEditMode) {
                this.form.empresa = (await getCompany()).id;
                this.form.usuario = (await getProfile()).id;
                this.form.tipo = TipoRelatorioAgendadoEnum.FUNCIONARIO;
                this.form.situacao = EnumSituacaoRelatoriosAgendados.ATIVO;
            } else {
                this.form.id = this.$route.params.id;
            }
            return this.form;
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onValidate(cbSuccess, cbError) {
            this.form.emailsEnvios = this.emailString.split(';').map((email) => email.trim());

            if (await this.hasError()) {
                this.$toast.add({ severity: 'error', summary: 'Há campos obrigatórios não preenchidos', life: 3000 });
                return cbError();
            }

            return cbSuccess();
        },
        async hasError() {
            this.diaValido = this.form.dia >= 1 && this.form.dia <= this.maxDia;
            this.emailsValidos = this.validateEmails(this.form.emailsEnvios);

            if (!this.form.cliente.length || !this.form.dia || !this.form.mesBase || !this.diaValido || !this.emailsValidos) {
                return true;
            }
            return false;
        },
        validateEmails(emails) {
            if (!emails || emails.length < 1) return false;

            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emails.every((email) => emailPattern.test(email.trim()));
        },
        validateDia() {
            this.diaValido = this.form.dia >= 1 && this.form.dia <= this.maxDia;
        }
    }
};
</script>
